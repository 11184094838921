import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { getAuth0Instance } from '@/auth0'


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginButton',
  setup(__props) {

const auth0 = getAuth0Instance()

function handleLogin() {
  auth0.loginWithRedirect({
    appState: { target: '/callback' },
  })
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: "btn btn-outline-primary",
    onClick: handleLogin
  }, "Login"))
}
}

})