import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["onUpdate:modelValue"]
const _hoisted_2 = ["onUpdate:modelValue"]
const _hoisted_3 = { class: "align-self-end" }
const _hoisted_4 = ["onClick"]

import { defineProps } from 'vue/dist/vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'DistanceCondition',
  props: {
  conditions: {
    type: Array,
    required: true,
  },
},
  setup(__props) {

const props = __props

function removeCondition(index: number) {
  const conditions = props.conditions
  conditions.splice(index, 1)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[2] || (_cache[2] = _createElementVNode("h4", null, "Distance Conditions", -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.conditions, (distanceCondition, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: distanceCondition.businessId,
        class: "bg-body-secondary d-flex flex-md-row flex-sm-column flex-wrap gap-3 mb-2 p-4 rounded-4"
      }, [
        _createElementVNode("div", null, [
          _cache[0] || (_cache[0] = _createElementVNode("label", {
            class: "form-label",
            for: "{{distanceCondition.businessId}}-minimum"
          }, "Minimum", -1)),
          _withDirectives(_createElementVNode("input", {
            id: "{{distanceCondition.businessId}}-minimum",
            "onUpdate:modelValue": ($event: any) => ((distanceCondition.minimum) = $event),
            class: "form-control",
            min: "0",
            type: "number"
          }, null, 8, _hoisted_1), [
            [_vModelText, distanceCondition.minimum]
          ])
        ]),
        _createElementVNode("div", null, [
          _cache[1] || (_cache[1] = _createElementVNode("label", {
            class: "form-label",
            for: "{{distanceCondition.businessId}}-maximum"
          }, "Maximum", -1)),
          _withDirectives(_createElementVNode("input", {
            id: "{{distanceCondition.businessId}}-maximum",
            "onUpdate:modelValue": ($event: any) => ((distanceCondition.maximum) = $event),
            class: "form-control",
            min: "0",
            type: "number"
          }, null, 8, _hoisted_2), [
            [_vModelText, distanceCondition.maximum]
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            class: "btn btn-danger",
            type: "button",
            onClick: ($event: any) => (removeCondition(index))
          }, "Remove", 8, _hoisted_4)
        ])
      ]))
    }), 128))
  ]))
}
}

})