<template>
  <div class="col-sm-12 p-3">
    <NavbarDefault />
    <div v-if="isLoading" class="col-sm-12 text-center">
      <div class="spinner-border" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
    <router-view class="col-sm-12 col-md-10 offset-md-1 col-xl-10 offset-xl-1"></router-view>
    <NotificationComponent />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import NavbarDefault from '@/components/NavbarDefault.vue'
import NotificationComponent from '@/components/NotificationComponent.vue'

@Options({
  data() {
    return {
      isLoading: this.$auth0.isLoading,
    }
  },
  components: {
    NavbarDefault,
    NotificationComponent,
  },
})
export default class App extends Vue {}
</script>
