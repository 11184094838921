<template>
  <input v-model="inputValue" :min="minimum" class="form-control" type="datetime-local" @change="save" />
</template>
<script lang="ts" setup>
import { defineProps } from 'vue/dist/vue'
import { defineEmits, ref } from 'vue'
import moment from 'moment-timezone'
import { DATE_TIME_FORMAT, ISO_FORMAT, NEW_YORK_ZONE, UTC_ZONE } from '@/model/acceptRide/constants/DateTimeConstants'

const props = defineProps({
  date: {
    type: String,
    required: true,
  },
})
const emit = defineEmits(['update:date'])
const date = ref(props.date)
// date is in UTC zone and ISO format, convert it to new york zone and DATE_TIME_FORMAT
const inputValue = ref(moment.tz(date.value, ISO_FORMAT, UTC_ZONE).tz(NEW_YORK_ZONE).format(DATE_TIME_FORMAT))
const minimum = moment().tz(NEW_YORK_ZONE).format(DATE_TIME_FORMAT)

function save() {
  // convert input value from NEW York zone with DATE_TIME_FORMAT to UTC Zone with ISO format
  date.value = moment.tz(inputValue.value, DATE_TIME_FORMAT, NEW_YORK_ZONE).tz(UTC_ZONE).format(ISO_FORMAT)
  emit('update:date', date.value)
}
</script>
