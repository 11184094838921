import { createApp, unref } from 'vue'
import App from './App.vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import { key, store } from './store'
import { createAuth0Instance } from '@/auth0'
import { createConfig } from '@/config'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import '@/assets/custom.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faCalendar,
  faCalendarCheck,
  faCar,
  faCircleCheck,
  faClipboard,
  faClock,
  faComment,
  faDollar,
  faHashtag,
  faLocationDot,
  faLocationPin,
  faPhone,
  faPlane,
  faRoad,
  faRotate,
  faRoute,
  faSpinner,
  faTruckFast,
  faUser,
  faUserTie,
  faVanShuttle,
  faWarning,
} from '@fortawesome/free-solid-svg-icons'
import { faClock as faClockRegular } from '@fortawesome/free-regular-svg-icons'
import ConditionSetListPage from '@/components/ConditionSetListPage.vue'
import CallbackPage from '@/components/CallbackPage.vue'
import HomePage from '@/components/HomePage.vue'
import ConditionSetPage from '@/components/ConditionSetPage.vue'

/* add icons to the library */
library.add(
  faCar,
  faVanShuttle,
  faUser,
  faPhone,
  faPlane,
  faLocationDot,
  faLocationPin,
  faRoad,
  faClock,
  faCalendar,
  faDollar,
  faSpinner,
  faCircleCheck,
  faWarning,
  faClipboard,
  faHashtag,
  faTruckFast,
  faCalendarCheck,
  faUserTie,
  faRoute,
  faRotate,
  faClockRegular,
  faComment
)
const config = await createConfig()

const routes = [
  { path: '/', component: HomePage },
  { name: 'callback', path: '/callback', component: CallbackPage },
  {
    name: 'conditionSets',
    path: '/conditionSets',
    component: ConditionSetListPage,
  },
  {
    name: 'conditionSet',
    path: '/conditionSet/:id',
    component: ConditionSetPage,
    props: true,
  },
  { path: '/dashboard', component: () => import('@/components/DashboardPage.vue') },
  { path: '/drivers', component: () => import('@/components/DriverListPage.vue') },
  { name: 'driver', path: '/driver/:id', component: () => import('@/components/DriverPage.vue'), props: true },
  { name: 'driverProfile', path: '/driverProfile', component: () => import('@/components/DriverPage.vue') },
  { path: '/import', component: () => import('@/components/ImportRidesPage.vue') },
  { path: '/ride-requests', component: () => import('@/components/RideRequestListPage.vue') },
  { name: 'ride', path: '/ride/:id', component: () => import('@/components/RidePage.vue'), props: true },
  { name: 'rides', path: '/rides', component: () => import('@/components/RideListPage.vue') },
]
const router = createRouter({
  history: createWebHashHistory(),
  routes: routes,
  linkActiveClass: 'active bg-body-secondary rounded-3',
})

router.beforeEach(async (to, from, next) => {
  if (to.path === '/' || to.path === '/callback') return next()
  await new Promise((resolve) => {
    const timer = setInterval(() => {
      if (!auth0.isLoading.value) {
        clearInterval(timer)
        resolve(null)
      }
    }, 100)
  })
  const isAuthenticated = auth0.isAuthenticated.value
  const user = unref(auth0.user)
  if (isAuthenticated && user && user[config.auth0RolesClaim]) {
    return next()
  } else {
    return next('/')
  }
})

// Get the auth0 instance
const auth0 = await createAuth0Instance()
const app = createApp(App)

app.config.errorHandler = (err, instance, info) => {
  console.error('An error occurred:', err)
  console.error('Component instance:', instance)
  console.error('Info:', info)
  // Optionally display an error message to the user
  // For example, you could display a global error component
}

app.config.globalProperties.$filters = {
  formatDate(date: string): string {
    return new Date(date).toLocaleDateString('en-US', {
      timeZone: 'America/New_York',
    })
  },
  formatCurrency(amount: number, currency: string): string {
    const currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
    return currencyFormatter.format(amount)
  },
}
app.use(router).use(auth0).use(store, key).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
