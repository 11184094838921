import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, vModelText as _vModelText, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col-sm-12 bg-body-secondary p-4 rounded-4"
}
const _hoisted_2 = { class: "col-md-3 col-sm-12 mb-2" }
const _hoisted_3 = { class: "form-check form-switch" }
const _hoisted_4 = {
  class: "form-check-label",
  for: "action"
}
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-md-6 col-sm-12 mb-2" }
const _hoisted_7 = { class: "col-md-3 col-sm-12 mb-2" }
const _hoisted_8 = { class: "col-md-3 col-sm-12 mb-2" }
const _hoisted_9 = { class: "col-md-3 col-sm-12 mb-2" }
const _hoisted_10 = ["value"]
const _hoisted_11 = { class: "row p-2" }
const _hoisted_12 = { class: "row mt-2" }
const _hoisted_13 = { class: "col-sm-4 pb-2" }
const _hoisted_14 = { class: "dropdown" }
const _hoisted_15 = {
  "aria-labelledby": "addConditionDropDown",
  class: "dropdown-menu"
}
const _hoisted_16 = { class: "col-sm-4 pb-2" }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "col-sm-4" }

import { onBeforeMount, reactive } from 'vue'
import { useStore } from '@/store'
import {
  createConditionSet,
  deleteConditionSet,
  getConditionSet,
  updateConditionSet,
} from '@/services/ConditionSetService'
import { ConditionSet, getNewConditionSet } from '@/model/acceptRide/ConditionSet'
import AirlineCondition from '@/components/conditions/AirlineCondition.vue'
import PickupCondition from '@/components/conditions/PickupCondition.vue'
import DropOffCondition from '@/components/conditions/DropOffCondition.vue'
import RelativeStartTimeCondition from '@/components/conditions/RelativeStartTimeCondition.vue'
import { HttpStatusCode } from 'axios'
import { getAllConditionSetActions } from '@/model/acceptRide/constants/ConditionSetAction'
import BookingTypeCondition from '@/components/conditions/BookingTypeCondition.vue'
import DayCondition from '@/components/conditions/DayCondition.vue'
import DistanceCondition from '@/components/conditions/DistanceCondition.vue'
import ServiceClassCondition from '@/components/conditions/ServiceClassCondition.vue'
import StartDateCondition from '@/components/conditions/StartDateCondition.vue'
import StartTimeCondition from '@/components/conditions/StartTimeCondition.vue'
import EndTimeCondition from '@/components/conditions/EndTimeCondition.vue'
import { useRouter } from 'vue-router'
import { getNewStartDateCondition } from '@/model/acceptRide/conditions/StartDateCondition'
import { getNewPickupCondition } from '@/model/acceptRide/conditions/PickupCondition'
import { getNewDropOffCondition } from '@/model/acceptRide/conditions/DropOffCondition'
import { getNewBookingTypeCondition } from '@/model/acceptRide/conditions/BookingTypeCondition'
import { getNewServiceClassCondition } from '@/model/acceptRide/conditions/ServiceClassCondition'
import { getNewDistanceCondition } from '@/model/acceptRide/conditions/DistanceCondition'
import { getNewDayCondition } from '@/model/acceptRide/conditions/DayCondition'
import { getNewStartTimeCondition } from '@/model/acceptRide/conditions/StartTimeCondition'
import { getNewRelativeStartTimeCondition } from '@/model/acceptRide/conditions/RelativeStartTimeCondition'
import { getNewEndTimeCondition } from '@/model/acceptRide/conditions/EndTimeCondition'
import { getNewNotification, NotificationType } from '@/model/Notification'


export default /*@__PURE__*/_defineComponent({
  __name: 'ConditionSetPage',
  props: {
  id: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const conditionSet = reactive<ConditionSet>(getNewConditionSet())
const store = useStore()
const router = useRouter()

const props = __props

function fetchConditionSet() {
  if (store.state.conditionSet == undefined || store.state.conditionSet.businessId !== props.id) {
    getConditionSet(props.id).then((response) => {
      Object.assign(conditionSet, response)
      store.state.conditionSet = response
    })
  } else {
    Object.assign(conditionSet, store.state.conditionSet)
  }
}

function submit() {
  if (isCreate()) {
    createNewConditionSet()
  } else {
    updateExistingConditionSet()
  }
}

function updateExistingConditionSet() {
  updateConditionSet(conditionSet)
    .then((response) => {
      if (response.status === HttpStatusCode.Ok) {
        store.state.conditionSet = response.data
        Object.assign(conditionSet, response.data)
        store.state.notifications.push(getNewNotification('Condition Set updated', NotificationType.SUCCESS))
      } else if (response.status === HttpStatusCode.PreconditionFailed) {
        store.state.notifications.push(
          getNewNotification('Old version of condition set. Please refresh page and try again', NotificationType.ERROR)
        )
      } else {
        store.state.notifications.push(getNewNotification('Condition Set could not be updated', NotificationType.ERROR))
      }
    })
    .catch((response) => {
      console.log(response)
    })
}

function createNewConditionSet() {
  createConditionSet(conditionSet)
    .then((response) => {
      if (response.status === HttpStatusCode.Ok) {
        store.state.conditionSet = conditionSet
        store.state.notifications.push(getNewNotification('Condition Set created', NotificationType.SUCCESS))
        router.push({
          name: 'conditionSet',
          params: {
            id: conditionSet.businessId,
          },
        })
      } else {
        store.state.notifications.push(getNewNotification('Condition Set could not be created', NotificationType.ERROR))
      }
    })
    .catch((response) => {
      console.log(response)
    })
}

function deleteExistingConditionSet() {
  deleteConditionSet(conditionSet.businessId)
    .then((response) => {
      if (response.status === HttpStatusCode.Ok) {
        store.state.notifications.push(getNewNotification('Condition Set deleted', NotificationType.SUCCESS))
        store.state.conditionSet = undefined
        router.push({
          name: 'conditionSets',
        })
      } else {
        store.state.notifications.push(getNewNotification('Condition Set could not be deleted', NotificationType.ERROR))
      }
    })
    .catch((response) => {
      console.log(response)
    })
}

function isCreate(): boolean {
  return props.id == 'new'
}

onBeforeMount(() => {
  if (!isCreate()) {
    fetchConditionSet()
  }
})

return (_ctx: any,_cache: any) => {
  return (conditionSet != null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", _hoisted_4, _toDisplayString(conditionSet.enabled ? 'Enabled' : 'Disabled'), 1),
            _withDirectives(_createElementVNode("input", {
              id: "enabled",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((conditionSet.enabled) = $event)),
              class: "form-check-input",
              type: "checkbox"
            }, null, 512), [
              [_vModelCheckbox, conditionSet.enabled]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[17] || (_cache[17] = _createElementVNode("label", {
              class: "form-label",
              for: "count"
            }, "Description", -1)),
            _withDirectives(_createElementVNode("input", {
              id: "count",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((conditionSet.description) = $event)),
              class: "form-control",
              type: "text"
            }, null, 512), [
              [_vModelText, conditionSet.description]
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _cache[18] || (_cache[18] = _createElementVNode("label", {
              class: "form-label",
              for: "count"
            }, "Count", -1)),
            _withDirectives(_createElementVNode("input", {
              id: "count",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((conditionSet.count) = $event)),
              class: "form-control",
              type: "number"
            }, null, 512), [
              [_vModelText, conditionSet.count]
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _cache[19] || (_cache[19] = _createElementVNode("label", {
              class: "form-label",
              for: "priority"
            }, "Priority", -1)),
            _withDirectives(_createElementVNode("input", {
              id: "priority",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((conditionSet.priority) = $event)),
              class: "form-control",
              type: "number"
            }, null, 512), [
              [_vModelText, conditionSet.priority]
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[20] || (_cache[20] = _createElementVNode("label", {
              class: "form-label",
              for: "action"
            }, "Action", -1)),
            _withDirectives(_createElementVNode("select", {
              id: "action",
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((conditionSet.action) = $event)),
              class: "form-select"
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(getAllConditionSetActions)(), (type) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: type,
                  value: type
                }, _toDisplayString(type), 9, _hoisted_10))
              }), 128))
            ], 512), [
              [_vModelSelect, conditionSet.action]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          (conditionSet.startDateConditions.length > 0)
            ? (_openBlock(), _createBlock(StartDateCondition, {
                key: 0,
                conditions: conditionSet.startDateConditions,
                class: "bg-body-tertiary p-4 mb-2 rounded-4"
              }, null, 8, ["conditions"]))
            : _createCommentVNode("", true),
          (conditionSet.pickupConditions.length > 0)
            ? (_openBlock(), _createBlock(PickupCondition, {
                key: 1,
                conditions: conditionSet.pickupConditions,
                class: "bg-body-tertiary p-4 mb-2 rounded-4"
              }, null, 8, ["conditions"]))
            : _createCommentVNode("", true),
          (conditionSet.dropOffConditions.length > 0)
            ? (_openBlock(), _createBlock(DropOffCondition, {
                key: 2,
                conditions: conditionSet.dropOffConditions,
                class: "bg-body-tertiary p-4 mb-2 rounded-4"
              }, null, 8, ["conditions"]))
            : _createCommentVNode("", true),
          (conditionSet.relativeStartTimeConditions.length > 0)
            ? (_openBlock(), _createBlock(RelativeStartTimeCondition, {
                key: 3,
                conditions: conditionSet.relativeStartTimeConditions,
                class: "bg-body-tertiary p-4 mb-2 rounded-4"
              }, null, 8, ["conditions"]))
            : _createCommentVNode("", true),
          (conditionSet.airlineConditions.length > 0)
            ? (_openBlock(), _createBlock(AirlineCondition, {
                key: 4,
                conditions: conditionSet.airlineConditions,
                class: "bg-body-tertiary p-4 mb-2 rounded-4"
              }, null, 8, ["conditions"]))
            : _createCommentVNode("", true),
          (conditionSet.bookingTypeConditions.length > 0)
            ? (_openBlock(), _createBlock(BookingTypeCondition, {
                key: 5,
                conditions: conditionSet.bookingTypeConditions,
                class: "bg-body-tertiary p-4 mb-2 rounded-4"
              }, null, 8, ["conditions"]))
            : _createCommentVNode("", true),
          (conditionSet.serviceClassConditions.length > 0)
            ? (_openBlock(), _createBlock(ServiceClassCondition, {
                key: 6,
                conditions: conditionSet.serviceClassConditions,
                class: "bg-body-tertiary p-4 mb-2 rounded-4"
              }, null, 8, ["conditions"]))
            : _createCommentVNode("", true),
          (conditionSet.distanceConditions.length > 0)
            ? (_openBlock(), _createBlock(DistanceCondition, {
                key: 7,
                conditions: conditionSet.distanceConditions,
                class: "bg-body-tertiary p-4 mb-2 rounded-4"
              }, null, 8, ["conditions"]))
            : _createCommentVNode("", true),
          (conditionSet.dayConditions.length > 0)
            ? (_openBlock(), _createBlock(DayCondition, {
                key: 8,
                conditions: conditionSet.dayConditions,
                class: "bg-body-tertiary p-4 mb-2 rounded-4"
              }, null, 8, ["conditions"]))
            : _createCommentVNode("", true),
          (conditionSet.startTimeConditions.length > 0)
            ? (_openBlock(), _createBlock(StartTimeCondition, {
                key: 9,
                conditions: conditionSet.startTimeConditions,
                class: "bg-body-tertiary p-4 mb-2 rounded-4"
              }, null, 8, ["conditions"]))
            : _createCommentVNode("", true),
          (conditionSet.endTimeConditions.length > 0)
            ? (_openBlock(), _createBlock(EndTimeCondition, {
                key: 10,
                conditions: conditionSet.endTimeConditions,
                class: "bg-body-tertiary p-4 mb-2 rounded-4"
              }, null, 8, ["conditions"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _cache[21] || (_cache[21] = _createElementVNode("button", {
                id: "addConditionDropDown",
                "aria-expanded": "false",
                class: "btn btn-success dropdown-toggle",
                "data-bs-toggle": "dropdown",
                type: "button"
              }, " Add Condition ", -1)),
              _createElementVNode("ul", _hoisted_15, [
                _createElementVNode("li", null, [
                  _createElementVNode("button", {
                    class: "dropdown-item",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (conditionSet.startDateConditions.push(_unref(getNewStartDateCondition)())))
                  }, " Start Date ")
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("button", {
                    class: "dropdown-item",
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (conditionSet.pickupConditions.push(_unref(getNewPickupCondition)())))
                  }, " Pickup ")
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("button", {
                    class: "dropdown-item",
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (conditionSet.dropOffConditions.push(_unref(getNewDropOffCondition)())))
                  }, " Drop off ")
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("button", {
                    class: "dropdown-item",
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (conditionSet.relativeStartTimeConditions.push(_unref(getNewRelativeStartTimeCondition)())))
                  }, " Relative Start Time ")
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("button", {
                    class: "dropdown-item",
                    onClick: _cache[9] || (_cache[9] = ($event: any) => (conditionSet.bookingTypeConditions.push(_unref(getNewBookingTypeCondition)())))
                  }, " Booking Type ")
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("button", {
                    class: "dropdown-item",
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (conditionSet.serviceClassConditions.push(_unref(getNewServiceClassCondition)())))
                  }, " Service Class ")
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("button", {
                    class: "dropdown-item",
                    onClick: _cache[11] || (_cache[11] = ($event: any) => (conditionSet.distanceConditions.push(_unref(getNewDistanceCondition)())))
                  }, " Distance ")
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("button", {
                    class: "dropdown-item",
                    onClick: _cache[12] || (_cache[12] = ($event: any) => (conditionSet.dayConditions.push(_unref(getNewDayCondition)())))
                  }, "Day")
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("button", {
                    class: "dropdown-item",
                    onClick: _cache[13] || (_cache[13] = ($event: any) => (conditionSet.startTimeConditions.push(_unref(getNewStartTimeCondition)())))
                  }, " Start Time ")
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("button", {
                    class: "dropdown-item",
                    onClick: _cache[14] || (_cache[14] = ($event: any) => (conditionSet.endTimeConditions.push(_unref(getNewEndTimeCondition)())))
                  }, " End Time ")
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("button", {
              class: "btn btn-primary",
              onClick: _cache[15] || (_cache[15] = ($event: any) => (submit()))
            }, [
              (isCreate())
                ? (_openBlock(), _createElementBlock("span", _hoisted_17, "Create"))
                : (_openBlock(), _createElementBlock("span", _hoisted_18, "Update")),
              _cache[22] || (_cache[22] = _createTextVNode(" Condition Set "))
            ])
          ]),
          _createElementVNode("div", _hoisted_19, [
            (!isCreate())
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "btn btn-danger",
                  onClick: _cache[16] || (_cache[16] = ($event: any) => (deleteExistingConditionSet()))
                }, "Delete"))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})