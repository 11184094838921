<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card text-center mb-3">
        <div class="card-header">Ride with us</div>
        <div class="card-body">
          <p>We offer high quality cars so that you can travel in luxury</p>
          <p>Book a ride with us today</p>
        </div>
      </div>
      <div class="card text-center">
        <div class="card-header">Book today</div>
        <div class="card-body">
          <form class="row g-3">
            <div class="col-md-6">
              <label class="form-label" for="name">Name</label>
              <input id="name" v-model="rideRequest.name" class="form-control" type="text" />
            </div>
            <div class="col-md-6">
              <label class="form-label" for="phoneNumber">Phone Number</label>
              <input id="phoneNumber" v-model="rideRequest.phoneNumber" class="form-control" type="text" />
            </div>
            <div class="col-12">
              <label class="form-label" for="pickupAddress">Pick up Address</label>
              <input
                id="pickupAddress"
                v-model="rideRequest.pickupAddress"
                class="form-control"
                placeholder="e.g 9 corona Dr, Bethpage NY, 11714"
                type="text" />
            </div>
            <div class="col-12">
              <label class="form-label" for="dropOffAddress">Drop Off Address</label>
              <input
                id="dropOffAddress"
                v-model="rideRequest.dropOffAddress"
                class="form-control"
                placeholder="e.g JFK Queens, NY, 11430"
                type="text" />
            </div>
            <div class="col-md-6">
              <label class="form-label" for="rideDate">Ride Date & Time</label>
              <input
                id="rideDate"
                v-model="rideDate"
                class="form-control"
                type="datetime-local"
                @change="dateChanged" />
            </div>
            <div class="col-12">
              <button class="btn btn-primary" @click="submit()">Request Ride</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { HttpStatusCode } from 'axios'
import { getNewNotification, NotificationType } from '@/model/Notification'
import { reactive } from 'vue'
import { getNewRideRequest, RideRequest } from '@/model/RideRequest'
import { useStore } from '@/store'
import { createRideRequest } from '@/services/RideRequestService'
import moment from 'moment-timezone'
import { DATE_TIME_FORMAT, ISO_FORMAT, NEW_YORK_ZONE, UTC_ZONE } from '@/model/acceptRide/constants/DateTimeConstants'

let rideRequest = reactive<RideRequest>(getNewRideRequest())
let rideDate: string = ''
const store = useStore()

function submit() {
  createRideRequest(rideRequest)
    .then((response) => {
      if (response.status === HttpStatusCode.Ok) {
        store.state.notifications.push(getNewNotification('Ride successfully requested', NotificationType.SUCCESS))
        rideRequest = reactive<RideRequest>(getNewRideRequest())
      } else {
        store.state.notifications.push(
          getNewNotification('There was an issue requesting ride. Please try again later', NotificationType.ERROR)
        )
      }
    })
    .catch((response) => {
      console.log(response)
    })
}

function dateChanged() {
  rideRequest.rideDate = moment.tz(rideDate, DATE_TIME_FORMAT, NEW_YORK_ZONE).tz(UTC_ZONE).format(ISO_FORMAT)
}
</script>
