export enum DAY_OF_WEEK {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
}

export function getAllDaysOfWeek(): DAY_OF_WEEK[] {
  return [
    DAY_OF_WEEK.Monday,
    DAY_OF_WEEK.Tuesday,
    DAY_OF_WEEK.Wednesday,
    DAY_OF_WEEK.Thursday,
    DAY_OF_WEEK.Friday,
    DAY_OF_WEEK.Saturday,
    DAY_OF_WEEK.Sunday,
  ]
}
