import { v4 as UUID_v4 } from 'uuid'
import { DAY_OF_WEEK } from '@/model/acceptRide/constants/DayOfWeek'

export class DayCondition {
  businessId: string
  dayOfWeek: DAY_OF_WEEK

  constructor(businessId: string, dayOfWeek: DAY_OF_WEEK) {
    this.businessId = businessId
    this.dayOfWeek = dayOfWeek
  }
}

export function getNewDayCondition() {
  return new DayCondition(UUID_v4(), DAY_OF_WEEK.Monday)
}
