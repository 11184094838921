import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-sm-12 text-center" }

import { getAuth0Instance } from '@/auth0'
import { useRoute, useRouter } from 'vue-router'
import { Config, getConfig } from '@/config'
import { hasAdminRole, hasDriverRole, hasRideAccepterRole } from '@/services/Auth0Service'


export default /*@__PURE__*/_defineComponent({
  __name: 'CallbackPage',
  setup(__props) {

const auth0 = getAuth0Instance()
const { isAuthenticated, user } = getAuth0Instance()
const config: Config = getConfig()

const route = useRoute()
const router = useRouter()
console.log(auth0.isAuthenticated)
if (route.query.redirectTo) {
  router.push(route.query.redirectTo as string)
} else if (hasAdminRole(config, isAuthenticated.value, user.value)) {
  router.push('/drivers')
} else if (hasDriverRole(config, isAuthenticated.value, user.value)) {
  router.push('/driverProfile')
} else if (hasRideAccepterRole(config, isAuthenticated.value, user.value)) {
  router.push('/conditionSets')
} else {
  router.push('/')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", {
      class: "spinner-border",
      role: "status"
    }, [
      _createElementVNode("span", { class: "sr-only" })
    ], -1)
  ])))
}
}

})