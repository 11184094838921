<template>
  <div>
    <h4>Day Conditions</h4>
    <div
      v-for="(dayCondition, index) in props.conditions"
      :key="dayCondition.businessId"
      class="bg-body-secondary d-flex flex-md-row flex-sm-column flex-wrap gap-3 mb-2 p-4 rounded-4">
      <div class="btn-group">
        <select v-model="dayCondition.dayOfWeek" class="form-select">
          <option v-for="type in getAllDaysOfWeek()" :key="type" :value="type">
            {{ type }}
          </option>
        </select>
      </div>
      <div class="align-self-end">
        <button class="btn btn-danger" type="button" @click="removeCondition(index)">Remove</button>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { defineProps } from 'vue/dist/vue'
import { getAllDaysOfWeek } from '@/model/acceptRide/constants/DayOfWeek'

const props = defineProps({
  conditions: {
    type: Array,
    required: true,
  },
})

function removeCondition(index: number) {
  const conditions = props.conditions
  conditions.splice(index, 1)
}
</script>
