import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "btn-group" }
const _hoisted_2 = ["onUpdate:modelValue"]
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "align-self-end" }
const _hoisted_5 = ["onClick"]

import { defineProps } from 'vue/dist/vue'
import { getAllServiceClasses } from '@/model/acceptRide/constants/ServiceClass'


export default /*@__PURE__*/_defineComponent({
  __name: 'ServiceClassCondition',
  props: {
  conditions: {
    type: Array,
    required: true,
  },
},
  setup(__props) {

const props = __props

function removeCondition(index: number) {
  const conditions = props.conditions
  conditions.splice(index, 1)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[0] || (_cache[0] = _createElementVNode("h4", null, "Service Class Conditions", -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.conditions, (serviceClassCondition, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: serviceClassCondition.businessId,
        class: "bg-body-secondary d-flex flex-md-row flex-sm-column flex-wrap gap-3 mb-2 p-4 rounded-4"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _withDirectives(_createElementVNode("select", {
            "onUpdate:modelValue": ($event: any) => ((serviceClassCondition.serviceClass) = $event),
            class: "form-select"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(getAllServiceClasses)(), (type) => {
              return (_openBlock(), _createElementBlock("option", {
                key: type,
                value: type
              }, _toDisplayString(type), 9, _hoisted_3))
            }), 128))
          ], 8, _hoisted_2), [
            [_vModelSelect, serviceClassCondition.serviceClass]
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            class: "btn btn-danger",
            type: "button",
            onClick: ($event: any) => (removeCondition(index))
          }, "Remove", 8, _hoisted_5)
        ])
      ]))
    }), 128))
  ]))
}
}

})