import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["min"]

import { defineProps } from 'vue/dist/vue'
import { ref } from 'vue'
import moment from 'moment-timezone'
import { DATE_TIME_FORMAT, ISO_FORMAT, NEW_YORK_ZONE, UTC_ZONE } from '@/model/acceptRide/constants/DateTimeConstants'


export default /*@__PURE__*/_defineComponent({
  __name: 'DateTimeInput',
  props: {
  date: {
    type: String,
    required: true,
  },
},
  emits: ['update:date'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit
const date = ref(props.date)
// date is in UTC zone and ISO format, convert it to new york zone and DATE_TIME_FORMAT
const inputValue = ref(moment.tz(date.value, ISO_FORMAT, UTC_ZONE).tz(NEW_YORK_ZONE).format(DATE_TIME_FORMAT))
const minimum = moment().tz(NEW_YORK_ZONE).format(DATE_TIME_FORMAT)

function save() {
  // convert input value from NEW York zone with DATE_TIME_FORMAT to UTC Zone with ISO format
  date.value = moment.tz(inputValue.value, DATE_TIME_FORMAT, NEW_YORK_ZONE).tz(UTC_ZONE).format(ISO_FORMAT)
  emit('update:date', date.value)
}

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputValue).value = $event)),
    min: _unref(minimum),
    class: "form-control",
    type: "datetime-local",
    onChange: save
  }, null, 40, _hoisted_1)), [
    [_vModelText, inputValue.value]
  ])
}
}

})