<template>
  <div>
    <h4>Ride Start Date Conditions</h4>
    <div
      v-for="(startDateCondition, index) in props.conditions"
      :key="startDateCondition.businessId"
      class="bg-body-secondary d-flex flex-md-row flex-sm-column flex-wrap gap-3 mb-2 p-4 rounded-4">
      <div>
        <label class="form-label" for="{{startDateCondition.businessId}}-startDate">Start Date</label>
        <DateTimeInput
          :date="startDateCondition.startDate"
          @update:date="startDateCondition.startDate = $event"></DateTimeInput>
      </div>
      <div>
        <label class="form-label" for="{{startDateCondition.businessId}}-endDate">End Date</label>
        <DateTimeInput
          :date="startDateCondition.endDate"
          @update:date="startDateCondition.endDate = $event"></DateTimeInput>
      </div>
      <div class="align-self-end">
        <button class="btn btn-danger" type="button" @click="removeCondition(index)">Remove</button>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { defineProps } from 'vue/dist/vue'
import DateTimeInput from '@/components/common/DateTimeInput.vue'
import { StartDateCondition } from '@/model/acceptRide/conditions/StartDateCondition'

const props = defineProps<{
  conditions: StartDateCondition[]
}>()

function removeCondition(index: number) {
  const conditions = props.conditions
  conditions.splice(index, 1)
}
</script>
