<template>
  <div class="col-sm-12">
    <div class="row pb-2">
      <div class="col-sm-6">
        <h2>Condition Sets</h2>
      </div>
      <div class="col-sm-3 offset-md-3 text-end">
        <button class="btn btn-primary" @click="createConditionSet()">Create ConditionSet</button>
      </div>
    </div>
    <table class="table table-bordered table-responsive">
      <thead>
        <tr class="table-primary">
          <th>Priority</th>
          <th>Description</th>
          <th>Count</th>
          <th>Action</th>
          <th>Enabled</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in conditionSets"
          :key="item.businessId"
          :class="getListItemClass(item)"
          role="button"
          @click="goToConditionSet(item)">
          <td>{{ item.priority }}</td>
          <td>{{ item.description }}</td>
          <td>{{ item.count }}</td>
          <td>{{ item.action }}</td>
          <td>{{ item.enabled }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useStore } from '@/store'
import { useRouter } from 'vue-router'
import { getAllConditionSets } from '@/services/ConditionSetService'
import { ConditionSet } from '@/model/acceptRide/ConditionSet'
import { CONDITION_SET_ACTION } from '@/model/acceptRide/constants/ConditionSetAction'

const conditionSets = ref<Array<ConditionSet>>([])
const store = useStore()
const router = useRouter()

function fetchAllConditionSets() {
  getAllConditionSets().then((response) => {
    conditionSets.value = response
  })
}

function goToConditionSet(conditionSet: ConditionSet) {
  store.state.conditionSet = conditionSet
  router.push({
    name: 'conditionSet',
    params: {
      id: conditionSet.businessId,
    },
  })
}

function createConditionSet() {
  router.push({
    name: 'conditionSet',
    params: {
      id: 'new',
    },
  })
}

function getListItemClass(conditionSet: ConditionSet) {
  return {
    'table-disabled': !conditionSet.enabled,
    'table-danger': conditionSet.enabled && conditionSet.action === CONDITION_SET_ACTION.REJECT,
    'table-success': conditionSet.enabled && conditionSet.action === CONDITION_SET_ACTION.ACCEPT,
  }
}

onMounted(() => {
  fetchAllConditionSets()
})
</script>
