<template>
  <button class="btn btn-outline-primary" @click="handleLogin">Login</button>
</template>

<script lang="ts" setup>
import { getAuth0Instance } from '@/auth0'

const auth0 = getAuth0Instance()

function handleLogin() {
  auth0.loginWithRedirect({
    appState: { target: '/callback' },
  })
}
</script>
