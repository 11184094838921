<template>
  <div class="input-group">
    <input v-model="val" class="form-control" min="0" type="number" @change="save" />
    <div class="btn-group">
      <select v-model="unit" class="form-select" @change="save">
        <option value="H">Hours</option>
        <option value="M">Minutes</option>
      </select>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { defineProps } from 'vue/dist/vue'
import { defineEmits, ref } from 'vue'

const props = defineProps({
  duration: {
    type: String,
    default: undefined,
  },
})
const emit = defineEmits(['update:duration'])
const duration = ref(props.duration)
const unit = ref(isDurationValid() ? duration.value?.charAt(duration.value.length - 1) : 'H')
const val = ref(isDurationValid() ? duration.value?.replace('PT', '').replace(unit.value ? unit.value : '', '') : '')

function save() {
  duration.value = 'PT' + val.value + unit.value
  emit('update:duration', duration.value)
}

function isDurationValid(): boolean {
  return duration.value !== undefined && duration.value.length > 3
}
</script>
