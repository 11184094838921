<template>
  <div>
    <h4>Airline Conditions</h4>
    <div
      v-for="(airlineCondition, index) in props.conditions"
      :key="airlineCondition.businessId"
      class="bg-body-secondary d-flex flex-md-row flex-sm-column flex-wrap gap-3 mb-2 p-4 rounded-4">
      <div>
        <label class="form-label" for="{{airlineCondition.businessId}}-contains">Contains</label>
        <input
          id="{{airlineCondition.businessId}}-contains"
          v-model="airlineCondition.contains"
          class="form-control"
          type="text" />
      </div>
      <div>
        <label class="form-label" for="{{airlineCondition.businessId}}-doesNotContain">Does Not contain</label>
        <input
          id="{{airlineCondition.businessId}}-doesNotContain"
          v-model="airlineCondition.doesNotContain"
          class="form-control"
          type="text" />
      </div>
      <div class="align-self-end">
        <button class="btn btn-danger" type="button" @click="removeCondition(index)">Remove</button>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { defineProps } from 'vue/dist/vue'

const props = defineProps({
  conditions: {
    type: Array,
    required: true,
  },
})

function removeCondition(index: number) {
  const conditions = props.conditions
  conditions.splice(index, 1)
}
</script>
