import { v4 as UUID_v4 } from 'uuid'
import { Config, getConfig } from '@/config'

export class RideRequest {
  businessId: string
  rideDate: string
  name: string
  phoneNumber: string
  pickupAddress: string
  dropOffAddress: string
  companyId: string

  constructor(
    businessId: string,
    rideDate: string,
    name: string,
    phoneNumber: string,
    pickupAddress: string,
    dropOffAddress: string,
    companyId: string
  ) {
    this.businessId = businessId
    this.rideDate = rideDate
    this.name = name
    this.phoneNumber = phoneNumber
    this.pickupAddress = pickupAddress
    this.dropOffAddress = dropOffAddress
    this.companyId = companyId
  }
}

export function getNewRideRequest(): RideRequest {
  const config: Config = getConfig()
  return new RideRequest(UUID_v4(), '', '', '', '', '', config.companyId)
}
