import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "align-self-end" }
const _hoisted_2 = ["onClick"]

import { defineProps } from 'vue/dist/vue'
import DateTimeInput from '@/components/common/DateTimeInput.vue'
import { StartDateCondition } from '@/model/acceptRide/conditions/StartDateCondition'


export default /*@__PURE__*/_defineComponent({
  __name: 'StartDateCondition',
  props: {
    conditions: {}
  },
  setup(__props: any) {

const props = __props

function removeCondition(index: number) {
  const conditions = props.conditions
  conditions.splice(index, 1)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[2] || (_cache[2] = _createElementVNode("h4", null, "Ride Start Date Conditions", -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.conditions, (startDateCondition, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: startDateCondition.businessId,
        class: "bg-body-secondary d-flex flex-md-row flex-sm-column flex-wrap gap-3 mb-2 p-4 rounded-4"
      }, [
        _createElementVNode("div", null, [
          _cache[0] || (_cache[0] = _createElementVNode("label", {
            class: "form-label",
            for: "{{startDateCondition.businessId}}-startDate"
          }, "Start Date", -1)),
          _createVNode(DateTimeInput, {
            date: startDateCondition.startDate,
            "onUpdate:date": ($event: any) => (startDateCondition.startDate = $event)
          }, null, 8, ["date", "onUpdate:date"])
        ]),
        _createElementVNode("div", null, [
          _cache[1] || (_cache[1] = _createElementVNode("label", {
            class: "form-label",
            for: "{{startDateCondition.businessId}}-endDate"
          }, "End Date", -1)),
          _createVNode(DateTimeInput, {
            date: startDateCondition.endDate,
            "onUpdate:date": ($event: any) => (startDateCondition.endDate = $event)
          }, null, 8, ["date", "onUpdate:date"])
        ]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("button", {
            class: "btn btn-danger",
            type: "button",
            onClick: ($event: any) => (removeCondition(index))
          }, "Remove", 8, _hoisted_2)
        ])
      ]))
    }), 128))
  ]))
}
}

})