import { v4 as UUID_v4 } from 'uuid'
import moment from 'moment-timezone'
import { ISO_FORMAT, UTC_ZONE } from '@/model/acceptRide/constants/DateTimeConstants'

export class StartDateCondition {
  businessId: string
  startDate: string
  endDate: string

  constructor(businessId: string, startDate: string, endDate: string) {
    this.businessId = businessId
    this.startDate = startDate
    this.endDate = endDate
  }
}

export function getNewStartDateCondition() {
  return new StartDateCondition(
    UUID_v4(),
    moment.tz(UTC_ZONE).format(ISO_FORMAT),
    moment.tz(UTC_ZONE).format(ISO_FORMAT)
  )
}
