import { v4 as UUID_v4 } from 'uuid'

export class DistanceCondition {
  businessId: string
  minimum: number
  maximum: number

  constructor(businessId: string, minimum: number, maximum: number) {
    this.businessId = businessId
    this.minimum = minimum
    this.maximum = maximum
  }
}

export function getNewDistanceCondition() {
  return new DistanceCondition(UUID_v4(), 0, 0)
}
