<template>
  <div v-if="conditionSet != null" class="col-sm-12 bg-body-secondary p-4 rounded-4">
    <div class="col-md-3 col-sm-12 mb-2">
      <div class="form-check form-switch">
        <label class="form-check-label" for="action">
          {{ conditionSet.enabled ? 'Enabled' : 'Disabled' }}
        </label>
        <input id="enabled" v-model="conditionSet.enabled" class="form-check-input" type="checkbox" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12 mb-2">
        <label class="form-label" for="count">Description</label>
        <input id="count" v-model="conditionSet.description" class="form-control" type="text" />
      </div>
      <div class="col-md-3 col-sm-12 mb-2">
        <label class="form-label" for="count">Count</label>
        <input id="count" v-model="conditionSet.count" class="form-control" type="number" />
      </div>
      <div class="col-md-3 col-sm-12 mb-2">
        <label class="form-label" for="priority">Priority</label>
        <input id="priority" v-model="conditionSet.priority" class="form-control" type="number" />
      </div>
      <div class="col-md-3 col-sm-12 mb-2">
        <label class="form-label" for="action">Action</label>
        <select id="action" v-model="conditionSet.action" class="form-select">
          <option v-for="type in getAllConditionSetActions()" :key="type" :value="type">
            {{ type }}
          </option>
        </select>
      </div>
    </div>

    <div class="row p-2">
      <StartDateCondition
        v-if="conditionSet.startDateConditions.length > 0"
        :conditions="conditionSet.startDateConditions"
        class="bg-body-tertiary p-4 mb-2 rounded-4"></StartDateCondition>
      <PickupCondition
        v-if="conditionSet.pickupConditions.length > 0"
        :conditions="conditionSet.pickupConditions"
        class="bg-body-tertiary p-4 mb-2 rounded-4"></PickupCondition>
      <DropOffCondition
        v-if="conditionSet.dropOffConditions.length > 0"
        :conditions="conditionSet.dropOffConditions"
        class="bg-body-tertiary p-4 mb-2 rounded-4"></DropOffCondition>
      <RelativeStartTimeCondition
        v-if="conditionSet.relativeStartTimeConditions.length > 0"
        :conditions="conditionSet.relativeStartTimeConditions"
        class="bg-body-tertiary p-4 mb-2 rounded-4"></RelativeStartTimeCondition>
      <AirlineCondition
        v-if="conditionSet.airlineConditions.length > 0"
        :conditions="conditionSet.airlineConditions"
        class="bg-body-tertiary p-4 mb-2 rounded-4"></AirlineCondition>
      <BookingTypeCondition
        v-if="conditionSet.bookingTypeConditions.length > 0"
        :conditions="conditionSet.bookingTypeConditions"
        class="bg-body-tertiary p-4 mb-2 rounded-4"></BookingTypeCondition>
      <ServiceClassCondition
        v-if="conditionSet.serviceClassConditions.length > 0"
        :conditions="conditionSet.serviceClassConditions"
        class="bg-body-tertiary p-4 mb-2 rounded-4"></ServiceClassCondition>
      <DistanceCondition
        v-if="conditionSet.distanceConditions.length > 0"
        :conditions="conditionSet.distanceConditions"
        class="bg-body-tertiary p-4 mb-2 rounded-4"></DistanceCondition>
      <DayCondition
        v-if="conditionSet.dayConditions.length > 0"
        :conditions="conditionSet.dayConditions"
        class="bg-body-tertiary p-4 mb-2 rounded-4"></DayCondition>
      <StartTimeCondition
        v-if="conditionSet.startTimeConditions.length > 0"
        :conditions="conditionSet.startTimeConditions"
        class="bg-body-tertiary p-4 mb-2 rounded-4"></StartTimeCondition>
      <EndTimeCondition
        v-if="conditionSet.endTimeConditions.length > 0"
        :conditions="conditionSet.endTimeConditions"
        class="bg-body-tertiary p-4 mb-2 rounded-4"></EndTimeCondition>
    </div>
    <div class="row mt-2">
      <div class="col-sm-4 pb-2">
        <div class="dropdown">
          <button
            id="addConditionDropDown"
            aria-expanded="false"
            class="btn btn-success dropdown-toggle"
            data-bs-toggle="dropdown"
            type="button">
            Add Condition
          </button>
          <ul aria-labelledby="addConditionDropDown" class="dropdown-menu">
            <li>
              <button class="dropdown-item" @click="conditionSet.startDateConditions.push(getNewStartDateCondition())">
                Start Date
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="conditionSet.pickupConditions.push(getNewPickupCondition())">
                Pickup
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="conditionSet.dropOffConditions.push(getNewDropOffCondition())">
                Drop off
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="conditionSet.relativeStartTimeConditions.push(getNewRelativeStartTimeCondition())">
                Relative Start Time
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="conditionSet.bookingTypeConditions.push(getNewBookingTypeCondition())">
                Booking Type
              </button>
            </li>
            <li>
              <button
                class="dropdown-item"
                @click="conditionSet.serviceClassConditions.push(getNewServiceClassCondition())">
                Service Class
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="conditionSet.distanceConditions.push(getNewDistanceCondition())">
                Distance
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="conditionSet.dayConditions.push(getNewDayCondition())">Day</button>
            </li>
            <li>
              <button class="dropdown-item" @click="conditionSet.startTimeConditions.push(getNewStartTimeCondition())">
                Start Time
              </button>
            </li>
            <li>
              <button class="dropdown-item" @click="conditionSet.endTimeConditions.push(getNewEndTimeCondition())">
                End Time
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm-4 pb-2">
        <button class="btn btn-primary" @click="submit()">
          <span v-if="isCreate()">Create</span>
          <span v-else>Update</span>
          Condition Set
        </button>
      </div>
      <div class="col-sm-4">
        <button v-if="!isCreate()" class="btn btn-danger" @click="deleteExistingConditionSet()">Delete</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, onBeforeMount, reactive } from 'vue'
import { useStore } from '@/store'
import {
  createConditionSet,
  deleteConditionSet,
  getConditionSet,
  updateConditionSet,
} from '@/services/ConditionSetService'
import { ConditionSet, getNewConditionSet } from '@/model/acceptRide/ConditionSet'
import AirlineCondition from '@/components/conditions/AirlineCondition.vue'
import PickupCondition from '@/components/conditions/PickupCondition.vue'
import DropOffCondition from '@/components/conditions/DropOffCondition.vue'
import RelativeStartTimeCondition from '@/components/conditions/RelativeStartTimeCondition.vue'
import { HttpStatusCode } from 'axios'
import { getAllConditionSetActions } from '@/model/acceptRide/constants/ConditionSetAction'
import BookingTypeCondition from '@/components/conditions/BookingTypeCondition.vue'
import DayCondition from '@/components/conditions/DayCondition.vue'
import DistanceCondition from '@/components/conditions/DistanceCondition.vue'
import ServiceClassCondition from '@/components/conditions/ServiceClassCondition.vue'
import StartDateCondition from '@/components/conditions/StartDateCondition.vue'
import StartTimeCondition from '@/components/conditions/StartTimeCondition.vue'
import EndTimeCondition from '@/components/conditions/EndTimeCondition.vue'
import { useRouter } from 'vue-router'
import { getNewStartDateCondition } from '@/model/acceptRide/conditions/StartDateCondition'
import { getNewPickupCondition } from '@/model/acceptRide/conditions/PickupCondition'
import { getNewDropOffCondition } from '@/model/acceptRide/conditions/DropOffCondition'
import { getNewBookingTypeCondition } from '@/model/acceptRide/conditions/BookingTypeCondition'
import { getNewServiceClassCondition } from '@/model/acceptRide/conditions/ServiceClassCondition'
import { getNewDistanceCondition } from '@/model/acceptRide/conditions/DistanceCondition'
import { getNewDayCondition } from '@/model/acceptRide/conditions/DayCondition'
import { getNewStartTimeCondition } from '@/model/acceptRide/conditions/StartTimeCondition'
import { getNewRelativeStartTimeCondition } from '@/model/acceptRide/conditions/RelativeStartTimeCondition'
import { getNewEndTimeCondition } from '@/model/acceptRide/conditions/EndTimeCondition'
import { getNewNotification, NotificationType } from '@/model/Notification'

const conditionSet = reactive<ConditionSet>(getNewConditionSet())
const store = useStore()
const router = useRouter()

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
})

function fetchConditionSet() {
  if (store.state.conditionSet == undefined || store.state.conditionSet.businessId !== props.id) {
    getConditionSet(props.id).then((response) => {
      Object.assign(conditionSet, response)
      store.state.conditionSet = response
    })
  } else {
    Object.assign(conditionSet, store.state.conditionSet)
  }
}

function submit() {
  if (isCreate()) {
    createNewConditionSet()
  } else {
    updateExistingConditionSet()
  }
}

function updateExistingConditionSet() {
  updateConditionSet(conditionSet)
    .then((response) => {
      if (response.status === HttpStatusCode.Ok) {
        store.state.conditionSet = response.data
        Object.assign(conditionSet, response.data)
        store.state.notifications.push(getNewNotification('Condition Set updated', NotificationType.SUCCESS))
      } else if (response.status === HttpStatusCode.PreconditionFailed) {
        store.state.notifications.push(
          getNewNotification('Old version of condition set. Please refresh page and try again', NotificationType.ERROR)
        )
      } else {
        store.state.notifications.push(getNewNotification('Condition Set could not be updated', NotificationType.ERROR))
      }
    })
    .catch((response) => {
      console.log(response)
    })
}

function createNewConditionSet() {
  createConditionSet(conditionSet)
    .then((response) => {
      if (response.status === HttpStatusCode.Ok) {
        store.state.conditionSet = conditionSet
        store.state.notifications.push(getNewNotification('Condition Set created', NotificationType.SUCCESS))
        router.push({
          name: 'conditionSet',
          params: {
            id: conditionSet.businessId,
          },
        })
      } else {
        store.state.notifications.push(getNewNotification('Condition Set could not be created', NotificationType.ERROR))
      }
    })
    .catch((response) => {
      console.log(response)
    })
}

function deleteExistingConditionSet() {
  deleteConditionSet(conditionSet.businessId)
    .then((response) => {
      if (response.status === HttpStatusCode.Ok) {
        store.state.notifications.push(getNewNotification('Condition Set deleted', NotificationType.SUCCESS))
        store.state.conditionSet = undefined
        router.push({
          name: 'conditionSets',
        })
      } else {
        store.state.notifications.push(getNewNotification('Condition Set could not be deleted', NotificationType.ERROR))
      }
    })
    .catch((response) => {
      console.log(response)
    })
}

function isCreate(): boolean {
  return props.id == 'new'
}

onBeforeMount(() => {
  if (!isCreate()) {
    fetchConditionSet()
  }
})
</script>
