import { v4 as UUID_v4 } from 'uuid'
import { SERVICE_CLASS } from '@/model/acceptRide/constants/ServiceClass'

export class ServiceClassCondition {
  businessId: string
  serviceClass: SERVICE_CLASS

  constructor(businessId: string, serviceClass: SERVICE_CLASS) {
    this.businessId = businessId
    this.serviceClass = serviceClass
  }
}

export function getNewServiceClassCondition() {
  return new ServiceClassCondition(UUID_v4(), SERVICE_CLASS.SUV)
}
