import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/logo.png'


const _hoisted_1 = { class: "col-sm-12 mb-5" }
const _hoisted_2 = { class: "navbar navbar-expand-lg" }
const _hoisted_3 = { class: "container-fluid" }
const _hoisted_4 = {
  id: "navbarSupportedContent",
  class: "collapse navbar-collapse justify-content-center"
}
const _hoisted_5 = { class: "navbar-nav mb-2 mb-lg-0 text-center" }
const _hoisted_6 = { class: "nav-item" }
const _hoisted_7 = { class: "nav-item" }
const _hoisted_8 = { class: "nav-item" }
const _hoisted_9 = { class: "nav-item" }
const _hoisted_10 = {
  key: 1,
  class: "nav-item"
}
const _hoisted_11 = {
  key: 2,
  class: "nav-item"
}
const _hoisted_12 = {
  key: 3,
  class: "nav-item"
}
const _hoisted_13 = {
  key: 4,
  class: "nav-item"
}

import { computed } from 'vue'
import { hasAdminRole, hasDriverRole, hasRideAccepterRole } from '@/services/Auth0Service'
import LoginButton from '@/components/login/LoginButton.vue'
import LogoutButton from '@/components/login/LogoutButton.vue'
import { getAuth0Instance } from '@/auth0'
import { Config, getConfig } from '@/config'

// const claims = ref<Record<string, any>>({});
// const isLoading = ref<boolean>(false);
// const currentPath = window.location.hash;

export default /*@__PURE__*/_defineComponent({
  __name: 'NavbarDefault',
  setup(__props) {

const { isAuthenticated, user } = getAuth0Instance()
const config: Config = getConfig()

const isAdmin = computed(() => {
  return hasAdminRole(config, isAuthenticated.value, user.value)
})

const isDriver = computed(() => {
  return hasDriverRole(config, isAuthenticated.value, user.value)
})

const isRideAccepter = computed(() => {
  return hasRideAccepterRole(config, isAuthenticated.value, user.value)
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[8] || (_cache[8] = _createElementVNode("button", {
          "aria-controls": "navbarSupportedContent",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation",
          class: "navbar-toggler",
          "data-bs-target": "#navbarSupportedContent",
          "data-bs-toggle": "collapse",
          type: "button"
        }, [
          _createElementVNode("span", { class: "navbar-toggler-icon" })
        ], -1)),
        _cache[9] || (_cache[9] = _createElementVNode("div", { class: "fw-bold" }, [
          _createElementVNode("span", null, [
            _createElementVNode("img", { src: _imports_0 })
          ]),
          _createTextVNode(" Graylane ")
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("ul", _hoisted_5, [
            _createElementVNode("li", _hoisted_6, [
              _createVNode(_component_router_link, {
                class: "nav-link",
                to: "/"
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("Home")
                ])),
                _: 1
              })
            ]),
            (isAdmin.value)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("li", _hoisted_7, [
                    _createVNode(_component_router_link, {
                      class: "nav-link",
                      to: "/drivers"
                    }, {
                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                        _createTextVNode("Drivers")
                      ])),
                      _: 1
                    })
                  ]),
                  _createElementVNode("li", _hoisted_8, [
                    _createVNode(_component_router_link, {
                      class: "nav-link",
                      to: "/ride-requests"
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("Ride Requests")
                      ])),
                      _: 1
                    })
                  ]),
                  _createElementVNode("li", _hoisted_9, [
                    _createVNode(_component_router_link, {
                      class: "nav-link",
                      to: "/rides"
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode("Rides")
                      ])),
                      _: 1
                    })
                  ])
                ], 64))
              : _createCommentVNode("", true),
            (isRideAccepter.value || isAdmin.value)
              ? (_openBlock(), _createElementBlock("li", _hoisted_10, [
                  _createVNode(_component_router_link, {
                    class: "nav-link",
                    to: "/conditionSets"
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode("Condition Sets")
                    ])),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (isRideAccepter.value || isAdmin.value)
              ? (_openBlock(), _createElementBlock("li", _hoisted_11, [
                  _createVNode(_component_router_link, {
                    class: "nav-link",
                    to: "/dashboard"
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode("Dashboard")
                    ])),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (isAdmin.value)
              ? (_openBlock(), _createElementBlock("li", _hoisted_12, [
                  _createVNode(_component_router_link, {
                    class: "nav-link",
                    to: "/import"
                  }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode("Import Rides")
                    ])),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (isDriver.value)
              ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
                  _createVNode(_component_router_link, {
                    class: "nav-link",
                    to: "/driverProfile"
                  }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode("Profile")
                    ])),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        (!_unref(isAuthenticated))
          ? (_openBlock(), _createBlock(LoginButton, { key: 0 }))
          : _createCommentVNode("", true),
        (_unref(isAuthenticated))
          ? (_openBlock(), _createBlock(LogoutButton, { key: 1 }))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})