import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "col-sm-12" }
const _hoisted_2 = { class: "row pb-2" }
const _hoisted_3 = { class: "col-sm-3 offset-md-3 text-end" }
const _hoisted_4 = { class: "table table-bordered table-responsive" }
const _hoisted_5 = ["onClick"]

import { onMounted, ref } from 'vue'
import { useStore } from '@/store'
import { useRouter } from 'vue-router'
import { getAllConditionSets } from '@/services/ConditionSetService'
import { ConditionSet } from '@/model/acceptRide/ConditionSet'
import { CONDITION_SET_ACTION } from '@/model/acceptRide/constants/ConditionSetAction'


export default /*@__PURE__*/_defineComponent({
  __name: 'ConditionSetListPage',
  setup(__props) {

const conditionSets = ref<Array<ConditionSet>>([])
const store = useStore()
const router = useRouter()

function fetchAllConditionSets() {
  getAllConditionSets().then((response) => {
    conditionSets.value = response
  })
}

function goToConditionSet(conditionSet: ConditionSet) {
  store.state.conditionSet = conditionSet
  router.push({
    name: 'conditionSet',
    params: {
      id: conditionSet.businessId,
    },
  })
}

function createConditionSet() {
  router.push({
    name: 'conditionSet',
    params: {
      id: 'new',
    },
  })
}

function getListItemClass(conditionSet: ConditionSet) {
  return {
    'table-disabled': !conditionSet.enabled,
    'table-danger': conditionSet.enabled && conditionSet.action === CONDITION_SET_ACTION.REJECT,
    'table-success': conditionSet.enabled && conditionSet.action === CONDITION_SET_ACTION.ACCEPT,
  }
}

onMounted(() => {
  fetchAllConditionSets()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col-sm-6" }, [
        _createElementVNode("h2", null, "Condition Sets")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "btn btn-primary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (createConditionSet()))
        }, "Create ConditionSet")
      ])
    ]),
    _createElementVNode("table", _hoisted_4, [
      _cache[2] || (_cache[2] = _createElementVNode("thead", null, [
        _createElementVNode("tr", { class: "table-primary" }, [
          _createElementVNode("th", null, "Priority"),
          _createElementVNode("th", null, "Description"),
          _createElementVNode("th", null, "Count"),
          _createElementVNode("th", null, "Action"),
          _createElementVNode("th", null, "Enabled")
        ])
      ], -1)),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(conditionSets.value, (item) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: item.businessId,
            class: _normalizeClass(getListItemClass(item)),
            role: "button",
            onClick: ($event: any) => (goToConditionSet(item))
          }, [
            _createElementVNode("td", null, _toDisplayString(item.priority), 1),
            _createElementVNode("td", null, _toDisplayString(item.description), 1),
            _createElementVNode("td", null, _toDisplayString(item.count), 1),
            _createElementVNode("td", null, _toDisplayString(item.action), 1),
            _createElementVNode("td", null, _toDisplayString(item.enabled), 1)
          ], 10, _hoisted_5))
        }), 128))
      ])
    ])
  ]))
}
}

})