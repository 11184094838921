<template>
  <div>
    <h4>Ride End Time Conditions</h4>
    <div
      v-for="(endTimeCondition, index) in props.conditions"
      :key="endTimeCondition.businessId"
      class="bg-body-secondary d-flex flex-md-row flex-sm-column flex-wrap gap-3 mb-2 p-4 rounded-4">
      <div>
        <label class="form-label" for="{{endTimeCondition.businessId}}-endTime">Start Time</label>
        <input
          id="{{endTimeCondition.businessId}}-endTime"
          v-model="endTimeCondition.startTime"
          class="form-control"
          type="time" />
      </div>
      <div>
        <label class="form-label" for="{{endTimeCondition.businessId}}-endTime">End Time</label>
        <input
          id="{{endTimeCondition.businessId}}-endTime"
          v-model="endTimeCondition.endTime"
          class="form-control"
          type="time" />
      </div>
      <div class="align-self-end">
        <button class="btn btn-danger" type="button" @click="removeCondition(index)">Remove</button>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { defineProps } from 'vue/dist/vue'

const props = defineProps({
  conditions: {
    type: Array,
    required: true,
  },
})

function removeCondition(index: number) {
  const conditions = props.conditions
  conditions.splice(index, 1)
}
</script>
