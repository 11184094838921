import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  ref: "container",
  class: "position-fixed toast-container bottom-0 end-0 p-3",
  style: {"z-index":"11"}
}
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "me-auto" }
const _hoisted_4 = { class: "toast-body" }

import { onUpdated, reactive } from 'vue'
import { Toast } from 'bootstrap'

import { useStore } from '@/store'
import { Notification, NotificationType } from '@/model/Notification'


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationComponent',
  setup(__props) {

const store = useStore()

const notifications = reactive(store.state.notifications)
onUpdated(() => {
  const hiddenToasts = notifications.filter((obj) => {
    return obj.show != true
  })
  hiddenToasts.forEach(function (notification) {
    const errorToast = document.getElementById(notification.id)
    if (errorToast) {
      const toast = new Toast(errorToast)
      toast.show()
      notification.show = true
      errorToast.addEventListener('hidden.bs.toast', function () {
        const indexOfObject = notifications.findIndex((item) => {
          return item.id === notification.id
        })
        if (indexOfObject !== -1) {
          notifications.splice(indexOfObject, 1)
        }
      })
    }
  })
})

function getClass(notification: Notification) {
  return {
    'bg-danger-subtle': notification.type === NotificationType.ERROR,
    'bg-success-subtle': notification.type === NotificationType.SUCCESS,
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(notifications, (notification) => {
      return (_openBlock(), _createElementBlock("div", {
        id: notification.id,
        key: notification.id,
        class: _normalizeClass([getClass(notification), "toast fade"]),
        "aria-atomic": "true",
        "aria-live": "assertive",
        "data-bs-delay": "15000",
        role: "alert"
      }, [
        _createElementVNode("div", {
          class: _normalizeClass([getClass(notification), "toast-header"])
        }, [
          _createElementVNode("strong", _hoisted_3, [
            (notification.type === _unref(NotificationType).ERROR)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode("Error")
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode("Success")
                ], 64))
          ]),
          _cache[0] || (_cache[0] = _createElementVNode("button", {
            "aria-label": "Close",
            class: "btn-close",
            "data-bs-dismiss": "toast",
            type: "button"
          }, null, -1))
        ], 2),
        _createElementVNode("div", _hoisted_4, _toDisplayString(notification.content), 1)
      ], 10, _hoisted_2))
    }), 128))
  ], 512))
}
}

})