import { v4 as UUID_v4 } from 'uuid'

export class RelativeStartTimeCondition {
  businessId: string
  before: string
  after: string

  constructor(businessId: string, before: string | undefined, after: string | undefined) {
    this.businessId = businessId
    this.before = before == undefined ? '' : before
    this.after = after == undefined ? '' : after
  }
}

export function getNewRelativeStartTimeCondition() {
  return new RelativeStartTimeCondition(UUID_v4(), '', '')
}
