export enum SERVICE_CLASS {
  Sedan = 'business',
  SUV = 'van',
}

export function getServiceClassIcon(serviceClass: SERVICE_CLASS): string {
  switch (serviceClass) {
    case SERVICE_CLASS.SUV:
      return 'fa-van-shuttle'
    case SERVICE_CLASS.Sedan:
      return 'fa-car'
    default:
      return 'fa-warning'
  }
}

export function getAllServiceClasses(): SERVICE_CLASS[] {
  return [SERVICE_CLASS.Sedan, SERVICE_CLASS.SUV]
}
