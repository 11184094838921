import { v4 as UUID_v4 } from 'uuid'
import { BOOKING_TYPE } from '@/model/acceptRide/constants/BookingType'

export class BookingTypeCondition {
  businessId: string
  bookingType: BOOKING_TYPE

  constructor(businessId: string, bookingType: BOOKING_TYPE) {
    this.businessId = businessId
    this.bookingType = bookingType
  }
}

export function getNewBookingTypeCondition() {
  return new BookingTypeCondition(UUID_v4(), BOOKING_TYPE.Hourly)
}
