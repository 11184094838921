import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives, isRef as _isRef, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-12" }
const _hoisted_3 = { class: "card text-center" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "row g-3" }
const _hoisted_6 = { class: "col-md-6" }
const _hoisted_7 = { class: "col-md-6" }
const _hoisted_8 = { class: "col-12" }
const _hoisted_9 = { class: "col-12" }
const _hoisted_10 = { class: "col-md-6" }
const _hoisted_11 = { class: "col-12" }

import { HttpStatusCode } from 'axios'
import { getNewNotification, NotificationType } from '@/model/Notification'
import { reactive } from 'vue'
import { getNewRideRequest, RideRequest } from '@/model/RideRequest'
import { useStore } from '@/store'
import { createRideRequest } from '@/services/RideRequestService'
import moment from 'moment-timezone'
import { DATE_TIME_FORMAT, ISO_FORMAT, NEW_YORK_ZONE, UTC_ZONE } from '@/model/acceptRide/constants/DateTimeConstants'


export default /*@__PURE__*/_defineComponent({
  __name: 'HomePage',
  setup(__props) {

let rideRequest = reactive<RideRequest>(getNewRideRequest())
let rideDate: string = ''
const store = useStore()

function submit() {
  createRideRequest(rideRequest)
    .then((response) => {
      if (response.status === HttpStatusCode.Ok) {
        store.state.notifications.push(getNewNotification('Ride successfully requested', NotificationType.SUCCESS))
        rideRequest = reactive<RideRequest>(getNewRideRequest())
      } else {
        store.state.notifications.push(
          getNewNotification('There was an issue requesting ride. Please try again later', NotificationType.ERROR)
        )
      }
    })
    .catch((response) => {
      console.log(response)
    })
}

function dateChanged() {
  rideRequest.rideDate = moment.tz(rideDate, DATE_TIME_FORMAT, NEW_YORK_ZONE).tz(UTC_ZONE).format(ISO_FORMAT)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[12] || (_cache[12] = _createElementVNode("div", { class: "card text-center mb-3" }, [
        _createElementVNode("div", { class: "card-header" }, "Ride with us"),
        _createElementVNode("div", { class: "card-body" }, [
          _createElementVNode("p", null, "We offer high quality cars so that you can travel in luxury"),
          _createElementVNode("p", null, "Book a ride with us today")
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "card-header" }, "Book today", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("form", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _cache[6] || (_cache[6] = _createElementVNode("label", {
                class: "form-label",
                for: "name"
              }, "Name", -1)),
              _withDirectives(_createElementVNode("input", {
                id: "name",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(rideRequest).name) = $event)),
                class: "form-control",
                type: "text"
              }, null, 512), [
                [_vModelText, _unref(rideRequest).name]
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _cache[7] || (_cache[7] = _createElementVNode("label", {
                class: "form-label",
                for: "phoneNumber"
              }, "Phone Number", -1)),
              _withDirectives(_createElementVNode("input", {
                id: "phoneNumber",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(rideRequest).phoneNumber) = $event)),
                class: "form-control",
                type: "text"
              }, null, 512), [
                [_vModelText, _unref(rideRequest).phoneNumber]
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _cache[8] || (_cache[8] = _createElementVNode("label", {
                class: "form-label",
                for: "pickupAddress"
              }, "Pick up Address", -1)),
              _withDirectives(_createElementVNode("input", {
                id: "pickupAddress",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(rideRequest).pickupAddress) = $event)),
                class: "form-control",
                placeholder: "e.g 9 corona Dr, Bethpage NY, 11714",
                type: "text"
              }, null, 512), [
                [_vModelText, _unref(rideRequest).pickupAddress]
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _cache[9] || (_cache[9] = _createElementVNode("label", {
                class: "form-label",
                for: "dropOffAddress"
              }, "Drop Off Address", -1)),
              _withDirectives(_createElementVNode("input", {
                id: "dropOffAddress",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(rideRequest).dropOffAddress) = $event)),
                class: "form-control",
                placeholder: "e.g JFK Queens, NY, 11430",
                type: "text"
              }, null, 512), [
                [_vModelText, _unref(rideRequest).dropOffAddress]
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _cache[10] || (_cache[10] = _createElementVNode("label", {
                class: "form-label",
                for: "rideDate"
              }, "Ride Date & Time", -1)),
              _withDirectives(_createElementVNode("input", {
                id: "rideDate",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(rideDate) ? (rideDate).value = $event : rideDate = $event)),
                class: "form-control",
                type: "datetime-local",
                onChange: dateChanged
              }, null, 544), [
                [_vModelText, _unref(rideDate)]
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("button", {
                class: "btn btn-primary",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (submit()))
              }, "Request Ride")
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})