<template>
  <div>
    <h4>Relative Start Time Conditions</h4>
    <div
      v-for="(relativeStartTimeCondition, index) in props.conditions"
      :key="relativeStartTimeCondition.businessId"
      class="bg-body-secondary d-flex flex-md-row flex-sm-column flex-wrap gap-3 mb-2 p-4 rounded-4">
      <div>
        <label class="form-label" for="{{relativeStartTimeCondition.businessId}}-before">Before</label>
        <DurationInput
          :duration="relativeStartTimeCondition.before"
          @update:duration="relativeStartTimeCondition.before = $event" />
      </div>
      <div>
        <label class="form-label" for="{{relativeStartTimeCondition.businessId}}-after">After</label>
        <DurationInput
          :duration="relativeStartTimeCondition.after"
          @update:duration="relativeStartTimeCondition.after = $event" />
      </div>
      <div class="align-self-end">
        <button class="btn btn-danger" type="button" @click="removeCondition(index)">Remove</button>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { defineProps } from 'vue/dist/vue'
import DurationInput from '@/components/common/DurationInput.vue'
import { RelativeStartTimeCondition } from '@/model/acceptRide/conditions/RelativeStartTimeCondition'

const props = defineProps<{
  conditions: RelativeStartTimeCondition[]
}>()

function removeCondition(index: number) {
  const conditions = props.conditions
  conditions.splice(index, 1)
}
</script>
