import { Config } from '@/config'
import { User } from '@auth0/auth0-spa-js'

export function hasAdminRole(config: Config, isAuthenticated: Boolean, user: User): Boolean {
  const rolesClaim = config.auth0RolesClaim
  return isAuthenticated && user[rolesClaim]?.includes('Admin')
}

export function hasDriverRole(config: Config, isAuthenticated: Boolean, user: User): Boolean {
  const rolesClaim = config.auth0RolesClaim
  return isAuthenticated && user[rolesClaim]?.includes('Driver')
}

export function hasRideAccepterRole(config: Config, isAuthenticated: Boolean, user: User): Boolean {
  const rolesClaim = config.auth0RolesClaim
  return isAuthenticated && user[rolesClaim]?.includes('RideAccepter')
}
