<template>
  <div>
    <h4>Distance Conditions</h4>
    <div
      v-for="(distanceCondition, index) in props.conditions"
      :key="distanceCondition.businessId"
      class="bg-body-secondary d-flex flex-md-row flex-sm-column flex-wrap gap-3 mb-2 p-4 rounded-4">
      <div>
        <label class="form-label" for="{{distanceCondition.businessId}}-minimum">Minimum</label>
        <input
          id="{{distanceCondition.businessId}}-minimum"
          v-model="distanceCondition.minimum"
          class="form-control"
          min="0"
          type="number" />
      </div>
      <div>
        <label class="form-label" for="{{distanceCondition.businessId}}-maximum">Maximum</label>
        <input
          id="{{distanceCondition.businessId}}-maximum"
          v-model="distanceCondition.maximum"
          class="form-control"
          min="0"
          type="number" />
      </div>
      <div class="align-self-end">
        <button class="btn btn-danger" type="button" @click="removeCondition(index)">Remove</button>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { defineProps } from 'vue/dist/vue'

const props = defineProps({
  conditions: {
    type: Array,
    required: true,
  },
})

function removeCondition(index: number) {
  const conditions = props.conditions
  conditions.splice(index, 1)
}
</script>
