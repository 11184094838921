<template>
  <div class="col-sm-12 mb-5">
    <div class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <button
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          class="navbar-toggler"
          data-bs-target="#navbarSupportedContent"
          data-bs-toggle="collapse"
          type="button">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="fw-bold">
          <span><img src="@/assets/logo.png" /></span>
          Graylane
        </div>
        <div id="navbarSupportedContent" class="collapse navbar-collapse justify-content-center">
          <ul class="navbar-nav mb-2 mb-lg-0 text-center">
            <li class="nav-item">
              <router-link class="nav-link" to="/">Home</router-link>
            </li>
            <template v-if="isAdmin">
              <li class="nav-item">
                <router-link class="nav-link" to="/drivers">Drivers</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/ride-requests">Ride Requests</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/rides">Rides</router-link>
              </li>
            </template>
            <template v-if="isRideAccepter || isAdmin">
              <li class="nav-item">
                <router-link class="nav-link" to="/conditionSets">Condition Sets</router-link>
              </li>
            </template>
            <template v-if="isRideAccepter || isAdmin">
              <li class="nav-item">
                <router-link class="nav-link" to="/dashboard">Dashboard</router-link>
              </li>
            </template>
            <template v-if="isAdmin">
              <li class="nav-item">
                <router-link class="nav-link" to="/import">Import Rides</router-link>
              </li>
            </template>
            <template v-if="isDriver">
              <li class="nav-item">
                <router-link class="nav-link" to="/driverProfile">Profile</router-link>
              </li>
            </template>
          </ul>
        </div>
        <template v-if="!isAuthenticated">
          <LoginButton></LoginButton>
        </template>
        <template v-if="isAuthenticated">
          <LogoutButton></LogoutButton>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { hasAdminRole, hasDriverRole, hasRideAccepterRole } from '@/services/Auth0Service'
import LoginButton from '@/components/login/LoginButton.vue'
import LogoutButton from '@/components/login/LogoutButton.vue'
import { getAuth0Instance } from '@/auth0'
import { Config, getConfig } from '@/config'

// const claims = ref<Record<string, any>>({});
// const isLoading = ref<boolean>(false);
// const currentPath = window.location.hash;
const { isAuthenticated, user } = getAuth0Instance()
const config: Config = getConfig()

const isAdmin = computed(() => {
  return hasAdminRole(config, isAuthenticated.value, user.value)
})

const isDriver = computed(() => {
  return hasDriverRole(config, isAuthenticated.value, user.value)
})

const isRideAccepter = computed(() => {
  return hasRideAccepterRole(config, isAuthenticated.value, user.value)
})
</script>
