import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "align-self-end" }
const _hoisted_2 = ["onClick"]

import { defineProps } from 'vue/dist/vue'
import DurationInput from '@/components/common/DurationInput.vue'
import { RelativeStartTimeCondition } from '@/model/acceptRide/conditions/RelativeStartTimeCondition'


export default /*@__PURE__*/_defineComponent({
  __name: 'RelativeStartTimeCondition',
  props: {
    conditions: {}
  },
  setup(__props: any) {

const props = __props

function removeCondition(index: number) {
  const conditions = props.conditions
  conditions.splice(index, 1)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[2] || (_cache[2] = _createElementVNode("h4", null, "Relative Start Time Conditions", -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.conditions, (relativeStartTimeCondition, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: relativeStartTimeCondition.businessId,
        class: "bg-body-secondary d-flex flex-md-row flex-sm-column flex-wrap gap-3 mb-2 p-4 rounded-4"
      }, [
        _createElementVNode("div", null, [
          _cache[0] || (_cache[0] = _createElementVNode("label", {
            class: "form-label",
            for: "{{relativeStartTimeCondition.businessId}}-before"
          }, "Before", -1)),
          _createVNode(DurationInput, {
            duration: relativeStartTimeCondition.before,
            "onUpdate:duration": ($event: any) => (relativeStartTimeCondition.before = $event)
          }, null, 8, ["duration", "onUpdate:duration"])
        ]),
        _createElementVNode("div", null, [
          _cache[1] || (_cache[1] = _createElementVNode("label", {
            class: "form-label",
            for: "{{relativeStartTimeCondition.businessId}}-after"
          }, "After", -1)),
          _createVNode(DurationInput, {
            duration: relativeStartTimeCondition.after,
            "onUpdate:duration": ($event: any) => (relativeStartTimeCondition.after = $event)
          }, null, 8, ["duration", "onUpdate:duration"])
        ]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("button", {
            class: "btn btn-danger",
            type: "button",
            onClick: ($event: any) => (removeCondition(index))
          }, "Remove", 8, _hoisted_2)
        ])
      ]))
    }), 128))
  ]))
}
}

})