import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "col-sm-12 p-3" }
const _hoisted_2 = {
  key: 0,
  class: "col-sm-12 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavbarDefault = _resolveComponent("NavbarDefault")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_NotificationComponent = _resolveComponent("NotificationComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavbarDefault),
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
          _createElementVNode("div", {
            class: "spinner-border",
            role: "status"
          }, [
            _createElementVNode("span", { class: "sr-only" })
          ], -1)
        ])))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view, { class: "col-sm-12 col-md-10 offset-md-1 col-xl-10 offset-xl-1" }),
    _createVNode(_component_NotificationComponent)
  ]))
}