import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { getAuth0Instance } from '@/auth0'


export default /*@__PURE__*/_defineComponent({
  __name: 'LogoutButton',
  setup(__props) {

const auth0 = getAuth0Instance()

function handleLogout() {
  auth0.logout({
    logoutParams: {
      returnTo: window.location.origin,
    },
  })
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: "btn btn-outline-danger",
    onClick: handleLogout
  }, "Logout"))
}
}

})