<template>
  <div class="col-sm-12 text-center">
    <div class="spinner-border" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { getAuth0Instance } from '@/auth0'
import { useRoute, useRouter } from 'vue-router'
import { Config, getConfig } from '@/config'
import { hasAdminRole, hasDriverRole, hasRideAccepterRole } from '@/services/Auth0Service'

const auth0 = getAuth0Instance()
const { isAuthenticated, user } = getAuth0Instance()
const config: Config = getConfig()

const route = useRoute()
const router = useRouter()
console.log(auth0.isAuthenticated)
if (route.query.redirectTo) {
  router.push(route.query.redirectTo as string)
} else if (hasAdminRole(config, isAuthenticated.value, user.value)) {
  router.push('/drivers')
} else if (hasDriverRole(config, isAuthenticated.value, user.value)) {
  router.push('/driverProfile')
} else if (hasRideAccepterRole(config, isAuthenticated.value, user.value)) {
  router.push('/conditionSets')
} else {
  router.push('/')
}
</script>
