<template>
  <div ref="container" class="position-fixed toast-container bottom-0 end-0 p-3" style="z-index: 11">
    <div
      v-for="notification in notifications"
      :id="notification.id"
      :key="notification.id"
      :class="getClass(notification)"
      aria-atomic="true"
      aria-live="assertive"
      class="toast fade"
      data-bs-delay="15000"
      role="alert">
      <div :class="getClass(notification)" class="toast-header">
        <strong class="me-auto">
          <template v-if="notification.type === NotificationType.ERROR">Error</template>
          <template v-else>Success</template>
        </strong>
        <button aria-label="Close" class="btn-close" data-bs-dismiss="toast" type="button"></button>
      </div>
      <div class="toast-body">{{ notification.content }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onUpdated, reactive } from 'vue'
import { Toast } from 'bootstrap'

import { useStore } from '@/store'
import { Notification, NotificationType } from '@/model/Notification'

const store = useStore()

const notifications = reactive(store.state.notifications)
onUpdated(() => {
  const hiddenToasts = notifications.filter((obj) => {
    return obj.show != true
  })
  hiddenToasts.forEach(function (notification) {
    const errorToast = document.getElementById(notification.id)
    if (errorToast) {
      const toast = new Toast(errorToast)
      toast.show()
      notification.show = true
      errorToast.addEventListener('hidden.bs.toast', function () {
        const indexOfObject = notifications.findIndex((item) => {
          return item.id === notification.id
        })
        if (indexOfObject !== -1) {
          notifications.splice(indexOfObject, 1)
        }
      })
    }
  })
})

function getClass(notification: Notification) {
  return {
    'bg-danger-subtle': notification.type === NotificationType.ERROR,
    'bg-success-subtle': notification.type === NotificationType.SUCCESS,
  }
}
</script>
