<template>
  <button class="btn btn-outline-danger" @click="handleLogout">Logout</button>
</template>

<script lang="ts" setup>
import { getAuth0Instance } from '@/auth0'

const auth0 = getAuth0Instance()

function handleLogout() {
  auth0.logout({
    logoutParams: {
      returnTo: window.location.origin,
    },
  })
}
</script>
