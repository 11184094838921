import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["onUpdate:modelValue"]
const _hoisted_2 = ["onUpdate:modelValue"]
const _hoisted_3 = { class: "align-self-end" }
const _hoisted_4 = ["onClick"]

import { defineProps } from 'vue/dist/vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'PickupCondition',
  props: {
  conditions: {
    type: Array,
    required: true,
  },
},
  setup(__props) {

const props = __props

function removeCondition(index: number) {
  const conditions = props.conditions
  conditions.splice(index, 1)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[2] || (_cache[2] = _createElementVNode("h4", null, "Pickup Conditions", -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.conditions, (pickupCondition, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: pickupCondition.businessId,
        class: "bg-body-secondary d-flex flex-md-row flex-sm-column flex-wrap gap-3 mb-2 p-4 rounded-4"
      }, [
        _createElementVNode("div", null, [
          _cache[0] || (_cache[0] = _createElementVNode("label", {
            class: "form-label",
            for: "{{pickupCondition.businessId}}-contains"
          }, "Contains", -1)),
          _withDirectives(_createElementVNode("input", {
            id: "{{pickupCondition.businessId}}-contains",
            "onUpdate:modelValue": ($event: any) => ((pickupCondition.contains) = $event),
            class: "form-control",
            type: "text"
          }, null, 8, _hoisted_1), [
            [_vModelText, pickupCondition.contains]
          ])
        ]),
        _createElementVNode("div", null, [
          _cache[1] || (_cache[1] = _createElementVNode("label", {
            class: "form-label",
            for: "{{pickupCondition.businessId}}-doesNotContain"
          }, "Does Not contain", -1)),
          _withDirectives(_createElementVNode("input", {
            id: "{{pickupCondition.businessId}}-doesNotContain",
            "onUpdate:modelValue": ($event: any) => ((pickupCondition.doesNotContain) = $event),
            class: "form-control",
            type: "text"
          }, null, 8, _hoisted_2), [
            [_vModelText, pickupCondition.doesNotContain]
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            class: "btn btn-danger",
            type: "button",
            onClick: ($event: any) => (removeCondition(index))
          }, "Remove", 8, _hoisted_4)
        ])
      ]))
    }), 128))
  ]))
}
}

})