export interface Config {
  auth0Domain: string
  auth0ClientId: string
  auth0RedirectUri: string
  auth0RolesClaim: string
  auth0Audience: string

  apiUrl: string
  companyId: string
}

let config: Config | null = null

export async function createConfig() {
  if (config === null) {
    const response = await fetch('/config.json')
    const value = await response.json()
    config = {
      auth0Domain: value.AUTH0_DOMAIN,
      auth0ClientId: value.AUTH0_CLIENT_ID,
      auth0RedirectUri: value.AUTH0_REDIRECT_URI,
      auth0RolesClaim: value.AUTH0_ROLES_CLAIM,
      auth0Audience: value.AUTH0_AUDIENCE,
      apiUrl: value.API_URL,
      companyId: value.COMPANY_ID,
    }
  }
  return config
}

export function getConfig(): Config {
  if (config === null) {
    throw new Error('Config instance is not initialized. Call createConfig first.')
  }
  return config
}
