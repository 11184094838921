import { defineComponent as _defineComponent } from 'vue'
import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vModelSelect as _vModelSelect, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-group" }
const _hoisted_2 = { class: "btn-group" }

import { defineProps } from 'vue/dist/vue'
import { ref } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'DurationInput',
  props: {
  duration: {
    type: String,
    default: undefined,
  },
},
  emits: ['update:duration'],
  setup(__props, { emit: __emit }) {

const props = __props
const emit = __emit
const duration = ref(props.duration)
const unit = ref(isDurationValid() ? duration.value?.charAt(duration.value.length - 1) : 'H')
const val = ref(isDurationValid() ? duration.value?.replace('PT', '').replace(unit.value ? unit.value : '', '') : '')

function save() {
  duration.value = 'PT' + val.value + unit.value
  emit('update:duration', duration.value)
}

function isDurationValid(): boolean {
  return duration.value !== undefined && duration.value.length > 3
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((val).value = $event)),
      class: "form-control",
      min: "0",
      type: "number",
      onChange: save
    }, null, 544), [
      [_vModelText, val.value]
    ]),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("select", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((unit).value = $event)),
        class: "form-select",
        onChange: save
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("option", { value: "H" }, "Hours", -1),
        _createElementVNode("option", { value: "M" }, "Minutes", -1)
      ]), 544), [
        [_vModelSelect, unit.value]
      ])
    ])
  ]))
}
}

})