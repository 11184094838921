import { v4 as UUID_v4 } from 'uuid'
import moment from 'moment-timezone'
import { TIME_FORMAT, UTC_ZONE } from '@/model/acceptRide/constants/DateTimeConstants'

export class EndTimeCondition {
  businessId: string
  startTime: string
  endTime: string

  constructor(businessId: string, startTime: string, endTime: string) {
    this.businessId = businessId
    this.startTime = startTime
    this.endTime = endTime
  }
}

export function getNewEndTimeCondition() {
  return new EndTimeCondition(
    UUID_v4(),
    moment.tz(UTC_ZONE).format(TIME_FORMAT),
    moment.tz(UTC_ZONE).format(TIME_FORMAT)
  )
}
